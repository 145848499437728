import React, { useState, useEffect } from "react";
import Header from "./Header";

const InfographicDetailPage = () => {
  const [currentInfographic, setCurrentInfographic] = useState(0);
  const [config, setConfig] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetch('/config/infographics.config.json');
        const data = await response.json();
        setConfig(data);
        setLoading(false);
      } catch (error) {
        console.error('Error loading config:', error);
        setLoading(false);
      }
    };
    
    fetchConfig();
  }, []);

  if (loading || !config) return <div>Loading...</div>;

  const { infographics, infographicText, infographicTitle } = config;
  const handlePrevious = () => {
    setCurrentInfographic((prev) =>
      prev === 0 ? infographics.length - 1 : prev - 1
    );
  };

  const handleNext = () => {
    setCurrentInfographic((prev) =>
      prev === infographics.length - 1 ? 0 : prev + 1
    );
  };

  const handleInfographicClick = (index) => {
    setCurrentInfographic(index);
  };

  return (
    <div
      style={{
        display: "flex",
        backgroundColor: "#0a0a0a",
        color: "white",
        minHeight: "100vh",
        position: "relative",
      }}
    >
      {/* Left Sidebar */}
      <nav
        style={{
          width: "423px",
          padding: "32px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100vh", // Full height for the sidebar
          position: "sticky", // Stick the sidebar in place
          top: 0,
          borderRight: "0.5px solid #b4ff39", // Vertical line beside sidebar
        }}
      >
        <div>
          <Header align="left" fontSize="20px" />

          <div
            style={{
              fontSize: "13px",
              color: "white",
              marginBottom: "32px",
              marginTop: "32px",
              position: "relative",
              top: "100px"
            }}
          >
            <h1
              style={{ color: "#b4ff39", fontSize: "20px", fontWeight: "bold" }}
            >
              {infographicTitle[currentInfographic]}
            </h1>
            <p>{infographicText[currentInfographic]}</p>
          </div>

          <div
            style={{
              position: "absolute",
              bottom: "0px",
            }}
          >
            {/* Add the horizontal line here */}
            <hr style={{ border: "none", borderTop: "1px solid #b4ff39", margin: "16px 0", width:"390px" }} />
            
            <h2
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                color: "#b4ff39",
                marginBottom: "8px",
              }}
            >
              Archive
            </h2>
            <ul style={{ listStyle: "none", padding: 0, marginBottom: "8px" }}>
              {infographicTitle.map((item, index) => (
                <li key={index} style={{ marginBottom: "4px" }}>
                  <a
                    href="#"
                    style={{
                      color: currentInfographic === index ? "#b4ff39" : "#888",
                      textDecoration: "none",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleInfographicClick(index)}
                  >
                    {item}
                  </a>
                </li>
              ))}
            </ul>
            <div style={{ display: "flex", gap: "8px" }}>
              <button
                style={{
                  color: "#b4ff39",
                  fontSize: "18px",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
                onClick={handlePrevious}
              >
                ▲
              </button>
              <button
                style={{
                  color: "#b4ff39",
                  fontSize: "18px",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
                onClick={handleNext}
              >
                ▼
              </button>
            </div>
          </div>
        </div>
      </nav>

      {/* Main Content */}
      <main
        style={{
          flex: 1,
          padding: "32px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* This div wraps the iframe and enables scrolling */}
        <div
          style={{
            flex: 1,
            overflowY: "auto", // Only the infographic area scrolls
            marginTop: "55px", // Moves the iframe lower
          }}
        >
          <iframe
            src={infographics[currentInfographic]}
            style={{
              width: "100%",
              height: "calc(100vh - 64px)",
              border: "none",
            }}
            title="Infographics recent"
          />
        </div>
      </main>

      <div
        style={{
          position: "absolute",
          top: "32px",
          right: "32px",
        }}
      >
        <a href="/">
          <img src="/assets/logo.svg" width="85px" alt="Infographic" />
        </a>
      </div>
    </div>
  );
};

export default InfographicDetailPage;