import React, { useState, useRef, useEffect } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";

export default function MobileInfographicScreen() {
  const [config, setConfig] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const touchStartX = useRef(0);
  const touchEndX = useRef(0);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetch('/config/infographics.config.json');
        const data = await response.json();
        setConfig(data);
        setLoading(false);
      } catch (error) {
        console.error('Error loading config:', error);
        setLoading(false);
      }
    };
    
    fetchConfig();
  }, []);
  useEffect(() => {
    setIsExpanded(false);
  }, [currentIndex]);
  if (loading || !config) return <div>Loading...</div>;

  const { infographics, infographicTitle, infographicText } = config;

  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    touchEndX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    const swipeDistance = touchStartX.current - touchEndX.current;
    if (Math.abs(swipeDistance) > 50) {
      swipeDistance > 0 ? nextInfographic() : previousInfographic();
    }
  };

  const nextInfographic = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = (prevIndex + 1) % infographics.length;
      console.log("Next Index:", newIndex); // Debugging log
      return newIndex;
    });
  };

  const previousInfographic = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex =
        (prevIndex - 1 + infographics.length) % infographics.length;
      console.log("Previous Index:", newIndex); // Debugging log
      return newIndex;
    });
  };

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  const handleLogoClick = (e) => {
    e.preventDefault(); // Prevent default page refresh
    window.scrollTo({ top: 0, behavior: "smooth" }); // Smoothly scroll to the top
  };

  return (
    <div className="flex flex-col min-h-screen bg-black text-white">
      <div className="p-4 text-center"> {/* Added text-center class for center alignment */}
        <h1 className="text-2xl font-bold text-[#b4ff39] mb-2">
          {infographicTitle[currentIndex]}
        </h1>
        <p className="text-sm mb-4">
          {isExpanded
            ? infographicText[currentIndex]
            : `${infographicText[currentIndex].slice(0, 100)}...`}
        </p>
        <button
          onClick={toggleReadMore}
          className="mt-2 text-xs text-[#D1FF4F]"
        >
          {isExpanded ? "Read Less" : "Read More ..."}
        </button>
      </div>

      <main
        className="flex-1 relative px-4"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        style={{
          marginBottom:"20px"
        }}
      >
        <iframe
          src={infographics[currentIndex]}
          title={infographicTitle[currentIndex]}
          className="w-full h-full border-none"
          key={currentIndex} // Force re-render on index change
          style={{ minHeight: "calc(100vh - 200px)" }}
        />
        <button
          onClick={previousInfographic}
          className="absolute left-0 top-1/2 transform -translate-y-1/2 text-[#b4ff39] bg-transparent border-none"
          aria-label="Previous infographic"
        >
          <ChevronLeft className="w-8 h-8" />
        </button>
        <button
          onClick={nextInfographic}
          className="absolute right-0 top-1/2 transform -translate-y-1/2 text-[#b4ff39] bg-transparent border-none"
          aria-label="Next infographic"
        >
          <ChevronRight className="w-8 h-8" />
        </button>
      </main>

      <footer className="bg-[#D64800] p-6 text-center">
        <h2 className="text-4xl font-bold text-[#D1FF4F] mb-2">
          We love this game.
        </h2>
        <p className="text-2xl text-[#D1FF4F] mb-4">ask us anything!</p>
        <p className="text-sm">
          Dive into the heart of tennis with us! We're your go-to source for all
          things tennis in Hampshire, England, blending high-energy insights
          with a deep passion for the game. we're here to keep your spirit high
          and your game sharp. Join us as we smash through the tennis world with
          enthusiasm, expertise, and a fierce sense of community.
        </p>
      </footer>

      <div className="bg-black p-4 flex flex-col items-center">
        <img
          src="/assets/logo_logoText.svg"
          alt="Tennis Hampshire Podcast"
          className="w-24 mb-4"
          onClick={handleLogoClick}
        />
        <div className="flex gap-4">
          <a
            href="https://www.facebook.com/people/The-Tennis-Hampshire-Podcast/61560728287138/"
            aria-label="Facebook"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/assets/socialMediaIcons/facebook.svg"
              alt=""
              className="w-5 h-5"
            />
          </a>
          <a
            href="https://www.instagram.com/thampshirepod/"
            aria-label="Instagram"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/assets/socialMediaIcons/insta.svg"
              alt=""
              className="w-5 h-5"
            />
          </a>
          <a
            href="https://x.com/thampshirepod"
            aria-label="Twitter"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/assets/socialMediaIcons/x.svg"
              alt=""
              className="w-5 h-5"
            />
          </a>
        </div>
        <p className="text-xs mt-4">
          © 2024 Tennis Hampshire Podcast. All rights reserved
        </p>
        {/* <div
          className="bg-black text-center text-white"
          style={{ fontSize: "10px", color: "white", marginTop: "10px" }}
        >
          Design and Branding by More Equal Design |{" "}
          <a
            href="https://www.mequald.com"
            target="_blank"
            rel="noopener noreferrer"
            className="underline"
          >
            www.mequald.com
          </a>
        </div> */}
      </div>
    </div>
  );
}
